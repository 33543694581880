import React from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Page from "../components/Page/Page";
import AboutUs from "../pages/AboutUs/AboutUs";
import OurWork from "../pages/OurWork/OurWork";
import Home from "../pages/Home/Home";
import Jobs from "../pages/Jobs/Jobs";
import Job from "../pages/Jobs/Job/Job";
import WorkWithUs from "../pages/Jobs/WorkWithUs/WorkWithUs";
import ApplyForm from "../pages/Jobs/ApplyForm/ApplyForm";
import NotFound from "../pages/NotFound/NotFound";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";

const Router = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/not-found" />} />
      {/* <Route path="/" element={<Navigate to="/home" />} /> */}
      <Route
        path="/"
        element={
          <Page>
            <Outlet />
            {location.pathname !== "/policy" && <GetInTouch />}{" "}
          </Page>
        }
      >
        <Route exact path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="our-work" element={<OurWork />} />
        <Route path="policy" element={<PrivacyPolicy />} />
      </Route>
      <Route path="jobs" element={<Jobs />}>
        <Route path="" element={<WorkWithUs />} />
        <Route path=":id" element={<Job />} />
        <Route path=":id/apply-now" element={<ApplyForm />} />
      </Route>
      <Route path="not-found" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
